import React from "react";
import sound1 from './sounds/click1.wav';
import './soundTest.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons'

export default class SoundTester extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bpm: 60,
            playing: false,
            vol: sessionStorage.getItem('volumeMetronome'),
            volText: (sessionStorage.getItem('volumeMetronome') * 100)
        }
        this.click1 = new Audio(sound1);
        this.click2 = new Audio(sound1);
        this.handleVol = this.handleVol.bind(this);
        this.updateInterval = this.updateInterval.bind(this);
        this.startStop = this.startStop.bind(this);
        this.playClick = this.playClick.bind(this);
    }

    updateInterval() {
        const bmpSpeed = 60 * 1000 / this.state.bpm;
        this.timer = setInterval(this.playClick, bmpSpeed);
    }

    handleVol(event) {
        const vol = event.target.value;
        this.setState({
            vol,
            volText: parseInt(vol * 100)
        });
        this.click1.volume = this.state.vol;
        sessionStorage.setItem('volumeMetronome', this.state.vol);
    }

    playClick() {
        this.click1.play().then(() => {
            
        }).catch((error) => {
            alert('Le son ne fonctionne pas avec votre installation ...');
        });
    }

    startStop() {
        if (this.state.playing) {
            clearInterval(this.timer);
            clearTimeout(this.timeOut);
            this.setState({
                playing: false
            });
        } else {
            this.updateInterval();
            this.setState({
                playing: true
            }, this.playClick)
            this.timeOut = setTimeout(() => {
                this.startStop()
                clearTimeout(this.timeOut)
            }, 3000);
        }
    }

    render() {
        return (
          <div id="volume-box">
              <Button handleClick={this.startStop} currentState={this.state.playing}/>
              <h4>Volume</h4>
              <Slider volText={this.state.volText} vol={this.state.vol} handleChange={this.handleVol} />
          </div>
        );
    }
}

class Button extends React.Component {
    render() {
        return (
            <button onClick={this.props.handleClick} title="Tester le volume">
              {this.props.currentState ? <FontAwesomeIcon icon={faStop} /> : <FontAwesomeIcon icon={faPlay} />}
            </button>
        );
    }
}

class Slider extends React.Component {
    render() {
        return (
            <div id="vol-slider">
                <div>{this.props.volText} %</div>
                <div className="slider-wrapper">
                    <input type="range" list="volRange" min="0" max="1" step="0.01" value={this.props.vol} onChange={this.props.handleChange}/>
                </div>
            </div>
        );
    }
}

// ReactDOM.render(<Metronome/>, document.getElementById("app"));