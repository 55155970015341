import React from "react";
import sound1 from './sounds/click1.wav';
import sound2 from './sounds/MetroBeat1.wav';
import './metro.css';


export default class Metronome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bpm: this.props.bpm,
            playing: false,
            count: 0,
            show: false,
        };
        this.showNotification = this.showNotification.bind(this);
        this.click1 = new Audio(sound1); //new Audio("https://daveceddia.com/freebies/react-metronome/click1.wav");
        this.click2 = new Audio(sound2); //new Audio("https://daveceddia.com/freebies/react-metronome/click1.wav");
        this.handleBPM = this.handleBPM.bind(this);
        this.updateInterval = this.updateInterval.bind(this);
        this.startStop = this.startStop.bind(this);
        this.playClick = this.playClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bpm !== this.props.bpm) {
          this.startStop();
          if(parseInt(prevProps.bpm) === 0) {
            this.click2.play();
          } else {
            this.click1.play();
            this.showNotification();
          }
        }
      }

    updateInterval() {
        const bmpSpeed = 60 * 1000 / this.props.bpm;
        this.timer = setInterval(this.playClick, bmpSpeed);
    }

    handleBPM() {
        const bpm = this.props.bpm;
        if (this.state.playing) {
            clearInterval(this.timer);
            this.updateInterval();
            this.setState({
                count: 0,
                bpm: bpm
            });
        } else {
            this.setState({
                bpm: bpm
            });
        };
    }

    playClick() {
        if (this.state.count !== 0) {
            this.click1.play();
            this.showNotification();
        }
        this.setState({
            count: this.state.count + 1
        });
    }

    startStop() {
        if (parseInt(this.props.bpm) === 0) {
            clearInterval(this.timer);
            this.setState({
                playing: false
            });
            this.click2.play();
        } else {
            clearInterval(this.timer);
            this.setState({
                count: 0,
                playing: true
            }, this.playClick);
            this.updateInterval();
        }
        // if (this.state.playing) {
        //     clearInterval(this.timer);
        //     this.setState({
        //         playing: false
        //     });
        // } else {
        //     this.updateInterval();
        //     this.setState({
        //         count: 0,
        //         playing: true
        //     }, this.playClick)
        // }
    }

    showNotification() {
        // You can use redux for this.
        this.setState({
          show: true,
        });
        setTimeout(() => {
          this.setState({
            show: false,
          });
        }, 200);
      }

    render() {
        return (
          <div>
              <Notification show={this.state.show} />
              {/* <h1>Metronome</h1>
              <Slider bpm={this.props.bpm} handleChange={this.handleBPM}/>
              <Button handleClick={this.startStop} currentState={this.state.playing}/> */}
          </div>
        );
    }
}

class Notification extends React.Component {
    render() {
        return <h2 className={this.props.show ? 'show' : ''}>TOP !</h2>
    }
}