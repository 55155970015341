import React, { useState, useEffect } from "react";
import Metronome from './metro.js';
import SoundTester from './soundTest.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
// import soundGo from './sounds/Go17.ogg';

// Params are placeholders in the URL that begin
// with a colon, like the `:id` param defined in
// the route in this example. A similar convention
// is used for matching dynamic segments in other
// popular web frameworks like Rails and Express.

export default function OpenSession() {
  return (
    <Router>
      <React.Fragment>
        <Switch>
          <Route exact path="/">
            <NoIdSession />
          </Route>
          <Route path="/:id">
            <LoadSession />
          </Route>
        </Switch>
      </React.Fragment>
    </Router>
  );
}

function NoIdSession() {
  return (
      <h3>Aucun exercice identifié.</h3>
  );
}

function LoadSession() {

  const {id} = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  // Remarque : le tableau vide de dépendances [] indique
  // que useEffect ne s’exécutera qu’une fois, un peu comme
  // componentDidMount()
  useEffect(() => {
    fetch(`./tasks/${id}.json`)
    // fetch(`https://maylis-app.firebaseio.com/${id}`)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setData(result);
        },
        // Remarque : il faut gérer les erreurs ici plutôt que dans
        // un bloc catch() afin que nous n’avalions pas les exceptions
        // dues à de véritables bugs dans les composants.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [id])

  if (error) {
    console.log(error.message);
    return <NoIdSession />;
  } else if (isLoaded && !error) {

    if(data.backSound !== '') {
      var backgroundSound = new Audio('./sounds/' + data.backSound);
    }
    return (
      <React.Fragment>
        <h1>{data.name}</h1>
        {/* <Parameters /> */}
        <StartingButton data={data.session} />
      </React.Fragment>
    );
  } else {
    return <h3>Chargement...</h3>;
  }

  function StartingButton() {
    const [showSession, setShowSession] = React.useState(false);

    const onClick = () => {
      setShowSession(true);
      if(backgroundSound) {   
        backgroundSound.play().then(() => {
          backgroundSound.volume = 1;
          console.log('son ok');
        }).catch((error) => {
          console.log('erreur');
          console.log(backgroundSound);
          console.log(error);
        });
      }
    };

    return (
      <React.Fragment>
        { showSession ? <LaunchSession /> : <button id="btnStartSession" onClick={onClick}>Démarrer !</button>}
      </React.Fragment>
    )
  }

  function LaunchSession() {
    return (
      <React.Fragment>
        <Timer startTimeInSeconds="0" />
        <Tasks data={data.session} />
      </React.Fragment>
    )
  }
}

class Tasks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      table: props.data,
      display: "",
      bpm: 0
    }
    // this.start = new Audio(soundGo);
  }

  componentDidMount() {
    // this.start.play();
    var i = 0;
    var timeTask = 0;
    this.setState({
      display: this.state.table[i].name,
      bpm: this.state.table[i].bpm
    });

    for(let i = 1; i <= this.state.table.length; i++) {
      timeTask = (parseInt(timeTask) + parseInt(this.state.table[i - 1].time));
      this.timer = setTimeout(() => {
        if(i === this.state.table.length) {
          this.setState({
            display: 'FIN',
            bpm: 0
          });
        } else {
          this.setState({
            display: this.state.table[i].name,
            bpm: this.state.table[i].bpm
          });
        }
      }, timeTask * 1000);
    }
  }

  componentWillUnmounnt() {
    clearTimeout(this.timer);
  }

  render() {
    return (
      <React.Fragment>
        <h4>{this.state.display}</h4>
        <Metronome bpm={this.state.bpm} />
      </React.Fragment>
    )
  }
}

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: parseInt(props.startTimeInSeconds, 10) || 0
    };
  }

  tick() {
    this.setState(state => ({
      seconds: state.seconds + 1
    }));
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  formatTime(secs) {
    let hours   = Math.floor(secs / 3600);
    let minutes = Math.floor(secs / 60) % 60;
    let seconds = secs % 60;
    return [hours, minutes, seconds]
        .map(v => ('' + v).padStart(2, '0'))
        .filter((v,i) => v !== '00' || i > 0)
        .join(':');
  }

  render() {
    return (
      <React.Fragment>
        Temps écoulé : {this.formatTime(this.state.seconds)}
      </React.Fragment>
    );
  }
}

const Parameters = () => {
  const [showResults, setShowResults] = React.useState(false)
  const onClick = () => {
    if(showResults) setShowResults(false)
    else setShowResults(true)
  }
  return (
    <div id="boxParameters">
      <button onClick={onClick}><FontAwesomeIcon icon={faCog} /></button>
      { showResults ? <SoundTester /> : null }
    </div>
  )
}